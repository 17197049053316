import { FooterLink, NavLink } from '@/models/link';
import {
	CalendarDaysIcon,
	DocumentTextIcon,
	EnvelopeIcon,
	GiftIcon,
	HomeIcon,
} from '@heroicons/react/24/solid';

const iconClasses = 'h-[1.125rem] w-[1.125rem] text-inherit grow-0 shrink-0 basis-[1.125rem]';

export const navLinks: NavLink[] = [
	{
		name: 'Accueil',
		link: '/',
		icon: <HomeIcon className={iconClasses} width={18} height={18} />,
		exact: true,
	},
	{
		name: 'Prestations',
		link: '/prestations',
		icon: <CalendarDaysIcon className={iconClasses} width={18} height={18} />,
		exact: false,
	},
	{
		name: 'Bon Cadeau',
		link: '/bon-cadeau',
		icon: <GiftIcon className={iconClasses} width={18} height={18} />,
		exact: false,
	},
	{
		name: 'Contact',
		link: 'mailto:petitchatnails@gmail.com',
		icon: <EnvelopeIcon className={iconClasses} width={18} height={18} />,
		exact: false,
	},
];

export const footerLinks: FooterLink[] = [
	{
		title: 'Pages',
		items: navLinks,
	},
	{
		title: 'Pages Légales',
		items: [
			{
				name: 'Mentions Légales',
				link: '/legale/mentions-legales',
				icon: <DocumentTextIcon className={iconClasses} width={18} height={18} />,
				exact: false,
			},
			{
				name: 'Politique de Confidentialité',
				link: '/legale/politique-de-confidentialite',
				icon: <DocumentTextIcon className={iconClasses} width={18} height={18} />,
				exact: false,
			},
			{
				name: 'Conditions Générales de Vente',
				link: '/legale/cgv',
				icon: <DocumentTextIcon className={iconClasses} width={18} height={18} />,
				exact: false,
			},
		],
	},
];
