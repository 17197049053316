'use client';

import { Collapse, IconButton, Navbar, Typography } from '@/components/material-tailwind';
import { navLinks } from '@/utils/links';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid';
import { useWindowSize } from '@uidotdev/usehooks';
import { clsx } from 'clsx';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { memo, useEffect, useState } from 'react';

const StickyNavbar = () => {
	const [openNav, setOpenNav] = useState(false);
	const size = useWindowSize();
	const pathname = usePathname();

	useEffect(() => {
		if (size.width === null) {
			return;
		}

		if (size.width >= 960) {
			setOpenNav(false);
		}
	}, [size]);

	const navList = (
		<ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
			{navLinks.map((item) => (
				<Typography as="li" variant="paragraph" className="p-1 font-normal" key={item.name}>
					<Link
						href={item.link}
						className={clsx(
							'flex items-center gap-2 text-sha-font underline-offset-2 transition-all',
							{
								'text-sha-orange underline': item.exact
									? item.link === pathname
									: pathname.startsWith(item.link),
								'hover:text-sha-orange hover:underline': item.exact
									? item.link !== pathname
									: !pathname.startsWith(item.link),
							},
						)}
						onClick={() => {
							setOpenNav(false);
						}}
						tabIndex={openNav ? 0 : -1}
					>
						{item.icon}
						{item.name}
					</Link>
				</Typography>
			))}
		</ul>
	);

	return (
		<header className="fixed left-0 right-0 top-4 z-50 px-5">
			<div className="container mx-auto">
				<Navbar className="relative z-50 mt-6 block w-full max-w-screen-2xl rounded-xl border-0 border-white/80 bg-white bg-opacity-80 px-8 py-3 pl-6 pr-3 text-sha-font shadow-md backdrop-blur-2xl backdrop-saturate-200">
					<div className="flex items-center justify-between text-sha-font">
						<Typography
							as="a"
							href="/"
							className="mr-4 cursor-pointer py-1.5 font-bold underline-offset-2 transition-all hover:text-sha-orange hover:underline focus:text-sha-orange focus:underline"
							onClick={() => {
								setOpenNav(false);
							}}
						>
							Petit Chat Nails
						</Typography>
						<div className="flex items-center gap-4">
							<div className="mr-4 hidden lg:block">{navList}</div>

							<IconButton
								variant="text"
								className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
								ripple={false}
								aria-label="Ouvrir le menu de navigation"
								onClick={() => {
									setOpenNav(!openNav);
								}}
							>
								{openNav ? (
									<XMarkIcon className="h-6 w-6 text-inherit" />
								) : (
									<Bars3Icon className="h-6 w-6 text-inherit" />
								)}
							</IconButton>
						</div>
					</div>
					<Collapse open={openNav} className="lg:hidden">
						{navList}
					</Collapse>
				</Navbar>
			</div>
		</header>
	);
};

export default memo(StickyNavbar);
